import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import logo from "../../img/logo_moreia.png"
import { NoLabelInput } from "../../components"
import { login } from "../../helpers/authApi"
class Login extends Component {
  constructor() {
    super()
    this.handleChange = this.handleChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.toggleLoading = this.toggleLoading.bind(this)
    this.state = {
      username: "",
      password: "",
      loading: false
    }
  }
  handleChange(e) {
    let name = e.target.name
    let value = e.target.value
    this.setState({
      [name]: value,
      error: undefined
    })
  }
  toggleLoading() {
    this.setState({
      loading: !this.state.loading
    })
  }
  onSubmit(e) {
    e.preventDefault()
    let params = {
      username: this.state.username,
      password: this.state.password
    }
    this.toggleLoading()
    login(params, error => {
      this.toggleLoading()
      if (error) {
        this.setState({
          error: "Credenciais inválidas"
        })
      } else {
        this.props.history.push("/movimentos")
      }
    })
  }
  render() {
    return (
      <div>
        <div className="bg-full bg-warning" />
        <div className="header bg-gradient-danger py-7 py-lg-8">
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              x="0"
              y="0"
              viewBox="0 0 2560 100"
              preserveAspectRatio="none"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <polygon
                className="fill-warning"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
        <div className="container mt--8">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8">
              <div className="card bg-secondary shadow border-0">
                <div className="card-header bg-transparent text-center">
                  <div className="row">
                    <div className="col-lg-12">
                      <img
                        src={logo}
                        className="img img-fluid"
                        style={{ maxHeight: 110 }}
                        alt="alucom"
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-lg-12">
                      <h2>Sistema de controle financeiro</h2>
                    </div>
                  </div>
                </div>
                <div className="card-body px-lg-5">
                  <div className="text-center text-muted mb-4">
                    <span>Entre com suas credenciais</span>
                  </div>
                  <form onSubmit={this.onSubmit}>
                    <NoLabelInput
                      name="username"
                      onChange={this.handleChange}
                      icon="ni ni-circle-08"
                      placeholder="Nome de usuário"
                      disabled={this.state.loading ? "disabled" : ""}
                    />
                    <NoLabelInput
                      name="password"
                      onChange={this.handleChange}
                      icon="ni ni-lock-circle-open"
                      placeholder="Senha"
                      type="password"
                      error={this.state.error}
                      disabled={this.state.loading ? "disabled" : ""}
                    />
                    <div className="text-center">
                      <button
                        disabled={this.state.loading ? "disabled" : ""}
                        type="submit"
                        className="btn btn-primary btn-lg btn-block mt-4"
                      >
                        Entrar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default withRouter(Login)
