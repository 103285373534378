import React, { Component } from "react"
import { Sidebar, Dropdown } from "."
import { withRouter } from "react-router-dom"
import { header } from "../helpers/dashboardApi"

class Layout extends Component {
  constructor() {
    super()
    this.getData = this.getData.bind(this)
    this.getHeaderData = this.getHeaderData.bind(this)
    this.state = {
      transferencias: 0,
      movimentos: 0
    }
  }

  getTitle() {
    // this.getHeaderData()
    if (window.location.pathname.includes("/dashboard")) {
      this.getData()
      return "Dashboard"
    }
    if (
      window.location.pathname.includes("/transferencias") &&
      !window.location.pathname.includes("Report")
    ) {
      return "Transferências"
    }
    if (window.location.pathname.includes("/movimentos")) {
      return "Movimentos"
    }
    if (window.location.pathname.includes("/especies")) {
      return "Espécies"
    }
    if (window.location.pathname.includes("/fontes")) {
      return "Fontes"
    }
    if (window.location.pathname.includes("/origens")) {
      return "Origens"
    }
    return "Relatórios"
  }
  getData() {
    //TODO
  }
  getHeaderData() {
    header((error, data) => {
      this.setState({
        transferencias: data.transferencias_count,
        movimentos: data.movimentos_count
      })
    })
  }
  render() {
    // let stats = [
    //   {
    //     name: "Total de movimentos",
    //     value: this.state.transferencias || 0,
    //     footerText: "Mês atual",
    //     color: "info"
    //   },
    //   {
    //     name: "Total de transferências",
    //     value: this.state.movimentos || 0,
    //     footerText: "Mês atual",
    //     color: "info"
    //   }
    // ]
    let menuItens = [
      // {
      //   label: "Dashboard",
      //   link: "/dashboard",
      //   icon: "ni ni-compass-04"
      // },
      {
        label: "Movimentos",
        link: "/movimentos",
        icon: "fas fa-money-check-alt"
      },
      {
        label: "Tipos de movimento",
        link: "/tipos",
        icon: "ni ni-bullet-list-67"
      },
      {
        label: "Transferências",
        link: "/transferencias",
        icon: "fas fa-exchange-alt"
      },
      {
        label: "Fontes",
        link: "/fontes",
        icon: "fas fa-archive"
      },
      {
        label: "Origens",
        link: "/origens",
        icon: "fas fa-hand-holding-usd"
      },
      {
        label: "Espécies",
        link: "/especies",
        icon: "fas fa-money-bill"
      },
      {
        label: "Relatórios",
        icon: "fas fa-chart-line",
        subItens: [
          { link: "/resumoGeral", label: "Resumo geral" },
          { link: "/movimentoGeral", label: "Movimento geral" },
          { link: "/movimentoCaixaGeral", label: "Movimento de caixa geral" },
          { link: "/movimentoApagar", label: "Movimentos a pagar" },
          { link: "/pagamentosPeriodo", label: "Pagamentos por período" },
          { link: "/transferenciasReport", label: "Transferências" }
        ]
      }
    ]
    return (
      <div>
        <Sidebar menuItens={menuItens} />
        <div className="main-content">
          <nav
            className="navbar navbar-top navbar-expand-md navbar-dark"
            id="navbar-main"
          >
            <div className="container-fluid">
              <h4 className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block">
                {this.getTitle()}
              </h4>
              <ul className="navbar-nav align-items-center d-none d-md-flex">
                <Dropdown icon="ni ni-single-02" />
              </ul>
            </div>
          </nav>
          <div className="header bg-gradient-danger pb-8 pt-5 pt-md-8">
            <div className="container-fluid">
              <div className="header-body" />
            </div>
          </div>
          <div className="container-fluid mt--7 pb-8">
            <div className="row">
              <div className="col">
                <div className="card shadow border-0">
                  {this.props.children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default withRouter(Layout)
