import React, { Component } from "react"
import { withRouter } from "react-router-dom"
class Modal extends Component {
  componentDidMount() {
    window.$("[data-toggle=\"tooltip\"]").tooltip({
      trigger: "hover"
    })
  }
  componentWillUnmount() {
    window.$(".tooltip").remove()
  }

  componentDidUpdate() {
    window.$("[data-toggle=\"tooltip\"]").tooltip({
      trigger: "hover"
    })
  }

  render() {
    return (
      <div className={this.props.margin || ""}>
        <button
          type="button"
          className={`btn btn${this.props.plain ? "" : "-outline"}-${this.props
            .color || "success"} ${this.props.md ? "btn-md" : ""}`}
          data-toggle="modal"
          data-target={`#${this.props.id}`}
        >
          <a
            data-toggle="tooltip"
            data-placement="top"
            title={this.props.triggerTitle}
          >
            <span className="btn-inner--text">
              {this.props.triggerLabel || " "}{" "}
            </span>
            <span className="btn-inner--icon">
              <i className={this.props.triggerIcon} />
            </span>
          </a>
        </button>
        <div
          className="modal fade"
          id={this.props.id}
          tabIndex="-1"
          style={{ zIndex: "999 !important" }}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel">
                  {this.props.title}
                </h3>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div
                className={`modal-body ${
                  this.props.centralized ? "text-center" : ""
                  }`}
              >
                {this.props.children}
              </div>
              <div className="modal-footer">{this.props.footer}</div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id={this.props.id}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog">
            <div
              className={
                "modal-content" + (this.props.centralized ? " text-center" : "")
              }
            >
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  &times;
                </button>
                <h4 className="modal-title" id="myModalLabel">
                  {this.props.title}
                </h4>
              </div>
              <div className="modal-body" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default withRouter(Modal)
