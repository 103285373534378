import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import IntlCurrencyInput from "react-intl-currency-input"
class InputCurrency extends Component {
  getConfig() {
    return {
      locale: "pt-BR",
      formats: {
        number: {
          BRL: {
            style: "currency",
            currency: "BRL",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }
        }
      }
    }
  }
  render() {
    return (
      <div className="form-group">
        <label className="form-control-label" htmlFor={this.props.name}>
          {this.props.label}
        </label>
        <IntlCurrencyInput
          defaultValue={this.props.defaultValue}
          currency="BRL"
          type="text"
          placeholder={this.props.placeholder}
          name={this.props.name}
          config={this.getConfig()}
          onKeyDown={this.props.onKeyDown}
          onChange={(e, value) =>
            this.props.onChange({
              target: { value: value, name: this.props.name }
            })
          }
          className={`form-control form-control-alternative ${
            this.props.error ? "has-danger" : ""
          }`}
          disabled={this.props.disabled}
        />
        {this.props.error && (
          <span className="text-danger error-span">{this.props.error}</span>
        )}
      </div>
    )
  }
}
export default withRouter(InputCurrency)
