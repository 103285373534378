import React, { Component } from "react"
import { withRouter, Link } from "react-router-dom"
import { get, destroy, search } from "../helpers/movimentosApi"
import moment from "moment"
import ReactPaginate from "react-paginate"
import { showNotification } from "../helpers/Constants"
import { confirmAlert } from "react-confirm-alert"
import ReactCSSTransitionGroup from "react-addons-css-transition-group"
import numeral from "../helpers/numeral"
import { Search } from "../components"
import housecall from "housecall"
import Collapsible from "react-collapsible"
import ReciboModal from "./ReciboModal"
import ModalXML from "./ModalXML";
class Movimentos extends Component {
  constructor() {
    super()
    this.getData = this.getData.bind(this)
    this.handlePageClick = this.handlePageClick.bind(this)
    this.togglePaginateLoading = this.togglePaginateLoading.bind(this)
    this.filter = this.filter.bind(this)
    this.toggleLoading = this.toggleLoading.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.queue = housecall({ concurrency: 1, cooldown: 0 })
    this.state = {
      movimentos: [],
      filtered: [],
      collapsed: [],
      pageCount: 0,
      filterFields: [],
      isLoading: true,
      searchActive: false,
      filterText: "",
      errors: []
    }
  }
  componentDidMount() {
    this.queue.on("idle", this.toggleLoading)
    window.$("[data-toggle=\"tooltip\"]").tooltip({
      trigger: "hover"
    })
  }
  componentWillUnmount() {
    window.$(".tooltip").remove()
  }

  componentDidUpdate() {
    window.$("[data-toggle=\"tooltip\"]").tooltip({
      trigger: "hover"
    })
  }
  togglePaginateLoading() {
    this.setState({
      isPaginationLoading: !this.state.isPaginationLoading
    })
  }
  toggleLoading() {
    this.setState({
      isLoading: !this.state.isLoading
    })
  }
  delete(id, index) {
    confirmAlert({
      title: "Confirme a remoção",
      message: "Tem certeza que deseja deletar?",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            destroy(id, error => {
              if (error) {
                let errorMessage = (
                    (error.response || { data: { message: undefined } }).data || {
                      message: undefined
                    }
                ).message
                showNotification(
                    "top",
                    "center",
                    errorMessage || "Erro desconhecido",
                    "danger"
                )
              } else {
                showNotification(
                    "top",
                    "center",
                    "Movimento deletado com sucesso",
                    "success"
                )
                let currentMovimentos = [...this.state.movimentos]
                currentMovimentos.splice(index, 1)
                this.setState({
                  movimentos: currentMovimentos
                })
              }
            })
          }
        },
        {
          label: "Não"
        }
      ]
    })
  }
  componentWillMount() {
    this.getData()
  }
  handlePageClick(data) {
    this.getData(data.selected + 1)
  }
  getData(page) {
    this.togglePaginateLoading()
    get(page, (error, data) => {
      this.togglePaginateLoading()
      if (error) {
        showNotification(
            "top",
            "center",
            "Ocorreram erros, verifique sua conexão com a internet",
            "danger"
        )
      } else {
        if (!page) {
          this.toggleLoading()
        }
        this.setState({
          movimentos: data.data,
          pageCount: data.last_page,
          collapsed: [].fill(false, 0, data.data.length)
        })
      }
    })
  }
  filterRequest() {
    if (this.state.filterText !== "") {
      let filtersCheckbox = this.state.filterFields.join(",") || [
        "id",
        "origem_id",
        "origem",
        "data_emissao",
        "valor",
        "faturamento",
        "historico",
        "documento"
      ]
      this.setState(
          {
            searchActive: true
          },
          () => {
            this.queue.push(() =>
                search(
                    this.state.filterText,
                    filtersCheckbox,
                    () => {
                      if (!this.state.isLoading) this.toggleLoading()
                    },
                    (error, data) => {
                      if (error) {
                        showNotification(
                            "top",
                            "center",
                            "Ocorreram erros, verifique sua conexão com a internet",
                            "danger"
                        )
                      } else {
                        this.setState({
                          filtered: data,
                          collapsed: [].fill(false, 0, data.length)
                        })
                      }
                    }
                )
            )
          }
      )
    } else {
      this.setState({
        collapsed: [].fill(false, 0, this.state.movimentos.length),
        searchActive: false
      })
    }
  }
  filter(e) {
    let value = e.target.value.toLowerCase()
    this.setState(
        {
          filterText: value
        },
        () => {
          this.filterRequest()
        }
    )
  }
  handleChange(e) {
    let name = e.target.name
    let checked = e.target.value
    if (checked) {
      if (name === "todos") {
        this.setState(
            {
              filterFields: []
            },
            () => {
              this.filterRequest()
            }
        )
      } else {
        this.setState(
            state => ({
              filterFields: [...state.filterFields, name]
            }),
            () => {
              this.filterRequest()
            }
        )
      }
    } else {
      this.setState(
          state => ({
            filterFields: state.filterFields.filter(item => {
              return item !== name
            })
          }),
          () => {
            this.filterRequest()
          }
      )
    }
  }
  render() {
    let searchColumns = [
      {
        value: "id",
        label: "Nº do movimento",
        checked: this.state.filterFields.includes("id")
      },
      {
        value: "origem_id",
        label: "Cód. da origem",
        checked: this.state.filterFields.includes("origem_id")
      },
      {
        value: "origem",
        label: "Origem",
        checked: this.state.filterFields.includes("origem")
      },
      {
        value: "numero_do_contrato",
        label: "Numero do contrato",
        checked: this.state.filterFields.includes("numero_do_contrato")
      },
      {
        value: "created_at",
        label: "Data da emissão (dd/mm/aaaa)",
        checked: this.state.filterFields.includes("created_at")
      },
      {
        value: "valor",
        label: "Valor (0.00)",
        checked: this.state.filterFields.includes("valor")
      },
      {
        value: "faturamento",
        label: "Valor pago",
        checked: this.state.filterFields.includes("faturamento")
      },
      {
        value: "documento",
        label: "Documento",
        checked: this.state.filterFields.includes("documento")
      },
      {
        value: "historico",
        label: "Histórico",
        checked: this.state.filterFields.includes("historico")
      }
    ]
    return (
        <div className="card shadow">
          <div className="card-header border-0 bg-secondary">
            <div className="row text-center">
              <div className="col-lg-12">
                <h1>Movimentos</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 offset-lg-4 text-center">
                <Link
                    to="/movimentos/create"
                    className="btn btn-success btn-block"
                >
                <span className="btn-inner--icon">
                  <i className="ni ni-fat-add" />
                </span>
                  <span className="btn-inner--text"> Adicionar</span>
                </Link>
              </div>
              <div className="col-lg-3 text-center">
                <ModalXML

                />
              </div>
            </div>
          </div>
          <div className="card-body">
            <Search
                searchColumns={searchColumns}
                filterTextChanged={this.filter}
                filterChanged={this.handleChange}
                noneSelected={this.state.filterFields.length === 0}
            />
            {this.state.isLoading ? (
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <div className="lds-ring">
                      <div />
                      <div />
                      <div />
                      <div />
                    </div>
                  </div>
                </div>
            ) : (
                <ReactCSSTransitionGroup
                    component="div"
                    className="px-0 container-fluid"
                    transitionEnterTimeout={500}
                    transitionLeaveTimeout={500}
                    transitionName="example"
                >
                  {(this.state.searchActive
                          ? this.state.filtered
                          : this.state.movimentos
                  ).map((item, key) => {
                    return (
                        <div key={key} className="card shadow">
                          <div className="card-header border-0 bg-white">
                            <div className="row">
                              <div className="col-lg-10">
                                <div className="row">
                                  <div className="col-lg-4">
                                    <strong>Nº do movimento: </strong> {item.id}
                                  </div>
                                  <div className="col-lg-8">
                                    <strong>Origem: </strong>
                                    {`${item.origem_id} - ${item.origem.nome}`}
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-12">
                                    <strong>Historico: </strong>
                                    {item.historico}
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-4">
                                    <strong>Data de vencimento: </strong>
                                    {item.data_movimento
                                        ? moment(item.data_movimento).format(
                                            "DD/MM/YYYY"
                                        )
                                        : "N/A"}
                                  </div>
                                  <div className="col-lg-4">
                                    <strong>Data de pagamento: </strong>
                                    {item.data_pagamento
                                        ? moment(item.data_pagamento).format(
                                            "DD/MM/YYYY"
                                        )
                                        : "N/A"}
                                  </div>
                                  <div className="col-lg-4">
                                    <strong>Cadastrado em: </strong>
                                    {item.created_at
                                        ? moment(item.created_at).format("DD/MM/YYYY")
                                        : "N/A"}
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-2">
                                <button
                                    className="btn btn-outline-primary"
                                    onClick={() => {
                                      let oldCollapsed = [...this.state.collapsed]
                                      oldCollapsed[key] = !oldCollapsed[key]
                                      this.setState({
                                        collapsed: oldCollapsed
                                      })
                                    }}
                                >
                                  <span className="btn-inner--text">Ver mais</span>
                                  <span className="btn-inner--icon">
                                <i
                                    className={`ni ni-${
                                        this.state.collapsed[key]
                                            ? "bold-up"
                                            : "bold-down"
                                    }`}
                                />
                              </span>
                                </button>
                              </div>
                            </div>
                          </div>
                          <Collapsible open={this.state.collapsed[key]}>
                            <div className="card-body bg-secondary">
                              <div className="row mb-3">
                                <div className="col-lg-4">
                                  <strong>Valor: </strong>
                                  {numeral(parseFloat(item.valor))
                                      .format("0.0,00")
                                      .slice(0, -2)}
                                </div>
                                <div className="col-lg-4">
                                  <strong>Espécie: </strong>
                                  {`${item.especie_id || ""} - ${
                                      (item.especie || { nome: "" }).nome
                                  }`}
                                </div>
                                <div className="col-lg-4">
                                  <strong>Fonte: </strong>
                                  {`${item.fonte_id || ""} - ${
                                      (item.fonte || { nome: "" }).nome
                                  }`}
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="col-lg-4">
                                  <strong>Valor pago: </strong>
                                  {numeral(parseFloat(item.faturamento))
                                      .format("0.0,00")
                                      .slice(0, -2)}
                                </div>
                                <div className="col-lg-4">
                                  <strong>Documento: </strong>
                                  {item.documento}
                                </div>
                                <div className="col-lg-4">
                                  <strong>Tipo de documento: </strong>
                                  {`${item.tipo_documento_id} - ${
                                      (item.tipo_documento || { nome: "" }).nome
                                  }`}
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="col-lg-8">
                                  <strong>Tipo de movimento: </strong>
                                  {`${item.tipo_id} - ${item.tipo.nome}`}
                                </div>
                                <div className="col-lg-4">
                                  <strong>Atualizado em: </strong>
                                  {item.updated_at
                                      ? moment(item.updated_at).format("DD/MM/YYYY")
                                      : "N/A"}
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="col-lg-8">
                                  <strong>Número do contrato: </strong>
                                  {item.numero_do_contrato}
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="offset-lg-4 col-lg-1">
                                  <Link
                                      title="Editar movimento"
                                      data-toggle="tooltip"
                                      to={`movimentos/edit/${item.id}`}
                                      className="btn btn-outline-info"
                                  >
                                <span className="btn-inner--icon">
                                  <i className="ni ni-ruler-pencil" />
                                </span>
                                  </Link>
                                </div>
                                <div className="col-lg-1">
                                  <button
                                      title="Deletar movimento"
                                      data-toggle="tooltip"
                                      onClick={() => this.delete(item.id, key)}
                                      type="button"
                                      className="btn btn-outline-danger"
                                  >
                                <span className="btn-inner--icon">
                                  <i className="ni ni-fat-remove" />
                                </span>
                                  </button>
                                </div>
                                <div className="col-lg-1">
                                  <ReciboModal
                                      movimentoId={item.id}
                                      valor={item.valor}
                                      cliente={item.origem.nome}
                                  />
                                </div>
                              </div>
                            </div>
                          </Collapsible>
                        </div>
                    )
                  })}
                </ReactCSSTransitionGroup>
            )}
          </div>
          <div className="card-footer">
            <ReactPaginate
                previousLabel={<i className="fa fa-angle-left" />}
                nextLabel={<i className="fa fa-angle-right" />}
                breakLabel={<i className="fa fa-ellipsis-h" />}
                breakClassName="break-me"
                pageCount={this.state.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={15}
                pageClassName={`page-item ${
                    this.state.searchActive || this.state.isPaginationLoading
                        ? "disabled"
                        : ""
                }`}
                onPageChange={this.handlePageClick}
                containerClassName="pagination justify-content-center"
                subContainerClassName={`page-item ${
                    this.state.searchActive || this.state.isPaginationLoading
                        ? "disabled"
                        : ""
                }`}
                pageLinkClassName="page-link"
                nextClassName={`page-item ${
                    this.state.searchActive || this.state.isPaginationLoading
                        ? "disabled"
                        : ""
                }`}
                nextLinkClassName="page-link"
                previousClassName={`page-item ${
                    this.state.searchActive || this.state.isPaginationLoading
                        ? "disabled"
                        : ""
                }`}
                previousLinkClassName="page-link"
                activeClassName="active"
            />
          </div>
        </div>
    )
  }
}
export default withRouter(Movimentos)