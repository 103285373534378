import React, { Component } from "react"
import { withRouter, Link } from "react-router-dom"
import { get, destroy, search } from "../helpers/transferenciasApi"
import moment from "moment"
import ReactPaginate from "react-paginate"
import { showNotification } from "../helpers/Constants"
import { confirmAlert } from "react-confirm-alert"
import ReactCSSTransitionGroup from "react-addons-css-transition-group"
import numeral from "../helpers/numeral"
import { Search } from "../components"
import housecall from "housecall"
class Transferencias extends Component {
  constructor() {
    super()
    this.getData = this.getData.bind(this)
    this.handlePageClick = this.handlePageClick.bind(this)
    this.togglePaginateLoading = this.togglePaginateLoading.bind(this)
    this.toggleLoading = this.toggleLoading.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.queue = housecall({ concurrency: 1, cooldown: 0 })
    this.filter = this.filter.bind(this)
    this.state = {
      transferencias: [],
      filterText: "",
      filtered: [],
      pageCount: 0,
      isLoading: true,
      filterFields: [],
      searchActive: false
    }
  }
  componentDidMount() {
    this.queue.on("idle", this.toggleLoading)
    window.$("[data-toggle=\"tooltip\"]").tooltip({
      trigger: "hover"
    })
  }
  componentWillUnmount() {
    window.$(".tooltip").remove()
  }

  componentDidUpdate() {
    window.$("[data-toggle=\"tooltip\"]").tooltip({
      trigger: "hover"
    })
  }
  togglePaginateLoading() {
    this.setState({
      isPaginationLoading: !this.state.isPaginationLoading
    })
  }
  toggleLoading() {
    this.setState({
      isLoading: !this.state.isLoading
    })
  }
  delete(id, index) {
    confirmAlert({
      title: "Confirme a remoção",
      message: "Tem certeza que deseja deletar?",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            destroy(id, error => {
              if (error) {
                let errorMessage = (
                  (error.response || { data: { message: undefined } }).data || {
                    message: undefined
                  }
                ).message
                showNotification(
                  "top",
                  "center",
                  errorMessage || "Erro desconhecido",
                  "danger"
                )
              } else {
                showNotification(
                  "top",
                  "center",
                  "Transferência deletada com sucesso",
                  "success"
                )
                let currentTransferencias = this.state.transferencias
                currentTransferencias.splice(index, 1)
                this.setState({
                  transferencias: currentTransferencias
                })
              }
            })
          }
        },
        {
          label: "Não"
        }
      ]
    })
  }
  componentWillMount() {
    this.getData()
  }
  handlePageClick(data) {
    this.getData(data.selected + 1)
  }
  getData(page) {
    this.togglePaginateLoading()
    get(page, (error, data) => {
      this.togglePaginateLoading()
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
      } else {
        if (!page) {
          this.toggleLoading()
        }
        this.setState({
          transferencias: data.data,
          pageCount: data.last_page
        })
      }
    })
  }
  filter(e) {
    let value = e.target.value.toLowerCase()
    this.setState(
      {
        filterText: value
      },
      () => {
        this.filterRequest()
      }
    )
  }
  filterRequest() {
    if (this.state.filterText !== "") {
      let filtersCheckbox = this.state.filterFields.join(",") || [
        "origem",
        "destino",
        "data",
        "valor",
        "historico",
        "destino_id",
        "origem_id",
        "origem_id"
      ]
      this.setState(
        {
          searchActive: true
        },
        () => {
          this.queue.push(() =>
            search(
              this.state.filterText,
              filtersCheckbox,
              () => {
                if (!this.state.isLoading) this.toggleLoading()
              },
              (error, data) => {
                if (error) {
                  showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                  )
                } else {
                  this.setState({
                    filtered: data
                  })
                }
              }
            )
          )
        }
      )
    } else {
      this.setState({
        searchActive: false
      })
    }
  }
  handleChange(e) {
    let name = e.target.name
    let checked = e.target.value
    if (checked) {
      if (name === "todos") {
        this.setState(
          {
            filterFields: []
          },
          () => {
            this.filterRequest()
          }
        )
      } else {
        this.setState(
          state => ({
            filterFields: [...state.filterFields, name]
          }),
          () => {
            this.filterRequest()
          }
        )
      }
    } else {
      this.setState(
        state => ({
          filterFields: state.filterFields.filter(item => {
            return item !== name
          })
        }),
        () => {
          this.filterRequest()
        }
      )
    }
  }
  render() {
    let searchColumns = [
      {
        value: "destino",
        label: "Destino",
        checked: this.state.filterFields.includes("destino")
      },
      {
        value: "origem",
        label: "Origem",
        checked: this.state.filterFields.includes("origem")
      },
      {
        value: "destino_id",
        label: "Cod. de Destino",
        checked: this.state.filterFields.includes("destino_id")
      },
      {
        value: "origem_id",
        label: "Cod. de Origem",
        checked: this.state.filterFields.includes("origem_id")
      },
      {
        value: "data",
        label: "Data",
        checked: this.state.filterFields.includes("data")
      },
      {
        value: "valor",
        label: "Valor",
        checked: this.state.filterFields.includes("valor")
      }
    ]

    return (
      <div className="card shadow">
        <div className="card-header border-0 bg-secondary">
          <div className="row text-center">
            <div className="col-lg-12">
              <h1>Tranferências</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 offset-lg-4 text-center">
              <Link
                to="/transferencias/create"
                className="btn btn-success btn-block"
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-fat-add" />
                </span>
                <span className="btn-inner--text"> Adicionar</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="card-body">
          <Search
            searchColumns={searchColumns}
            filterTextChanged={this.filter}
            filterChanged={this.handleChange}
            noneSelected={this.state.filterFields.length === 0}
          />
          {this.state.isLoading ? (
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="lds-ring">
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              </div>
            </div>
          ) : (
            <div className="table-responsive">
              <table className="table align-items-center table-flush">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Cod. de Origem</th>
                    <th scope="col">Origem</th>
                    <th scope="col">Cod. de Destino</th>
                    <th scope="col">Destino</th>
                    <th scope="col">Data</th>
                    <th scope="col">Histórico</th>
                    <th scope="col">Valor</th>
                    <th style={{ width: "15%" }} scope="col">
                      Ações
                    </th>
                  </tr>
                </thead>
                <ReactCSSTransitionGroup
                  component="tbody"
                  transitionEnterTimeout={500}
                  transitionLeaveTimeout={500}
                  transitionName="example"
                >
                  {(this.state.searchActive
                    ? this.state.filtered
                    : this.state.transferencias
                  ).map((item, key) => {
                    return (
                      <tr key={key}>
                        <td className="normal-wrap">{item.id}</td>
                        <td className="normal-wrap">{item.origem_id}</td>
                        <td className="normal-wrap">{item.origem.nome}</td>
                        <td className="normal-wrap">{item.destino_id}</td>
                        <td className="normal-wrap">{item.destino.nome}</td>
                        <td className="normal-wrap">
                          {moment(item.data).format("DD/MM/YYYY")}
                        </td>
                        <td className="normal-wrap">
                          {numeral(parseFloat(item.valor))
                            .format("0.0,00")
                            .slice(0, -2)}
                        </td>
                        <td className="normal-wrap">{item.historico}</td>
                        <td className="normal-wrap">
                          <Link
                            to={`transferencias/edit/${item.id}`}
                            className="btn btn-outline-info"
                            data-toggle="tooltip"
                            title="Editar transferência"
                          >
                            <span className="btn-inner--icon">
                              <i className="ni ni-ruler-pencil" />
                            </span>
                          </Link>
                          <button
                            onClick={() => this.delete(item.id, key)}
                            type="button"
                            className="btn btn-outline-danger"
                            data-toggle="tooltip"
                            title="Deletar transferência"
                          >
                            <span className="btn-inner--icon">
                              <i className="ni ni-fat-remove" />
                            </span>
                          </button>
                        </td>
                      </tr>
                    )
                  })}
                </ReactCSSTransitionGroup>
              </table>
            </div>
          )}
        </div>
        <div className="card-footer">
          <ReactPaginate
            previousLabel={<i className="fa fa-angle-left" />}
            nextLabel={<i className="fa fa-angle-right" />}
            breakLabel={<i className="fa fa-ellipsis-h" />}
            breakClassName="break-me"
            pageCount={this.state.pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={15}
            pageClassName={`page-item ${
              this.state.searchActive || this.state.isPaginationLoading
                ? "disabled"
                : ""
            }`}
            onPageChange={this.handlePageClick}
            containerClassName="pagination justify-content-center"
            subContainerClassName={`page-item ${
              this.state.searchActive || this.state.isPaginationLoading
                ? "disabled"
                : ""
            }`}
            pageLinkClassName="page-link"
            nextClassName={`page-item ${
              this.state.searchActive || this.state.isPaginationLoading
                ? "disabled"
                : ""
            }`}
            nextLinkClassName="page-link"
            previousClassName={`page-item ${
              this.state.searchActive || this.state.isPaginationLoading
                ? "disabled"
                : ""
            }`}
            previousLinkClassName="page-link"
            activeClassName="active"
          />
        </div>
      </div>
    )
  }
}
export default withRouter(Transferencias)
