import React, { Component } from "react"
import { InputCurrency, Input } from "../components"
import Modal from "../components/Modal"
import numeral from "../helpers/numeral"
import { recibo, importaXml } from "../helpers/reportsApi"
import { showNotification, mapErrors } from "../helpers/Constants"
export default class ModalXML extends Component {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.state = {
            errors: [],
            control: false,
            controlErr: ""
        }
    }
    handleChange(e) {
        let name = e.target.name
        let value = e.target.files[0]
        let errors = this.state.errors
        errors[name] = undefined
        this.setState({
            [name]: value,
            errors: errors,
            control:false
        })
    }
    toggleLoading() {
        this.setState(state => ({
            isLoading: !state.isLoading
        }))
    }
    onSubmit(e) {
        e.preventDefault()
        const params = new FormData()
        params.append("xml",this.state.xml)
        this.toggleLoading()
        importaXml(params, (error, data) => {
            this.toggleLoading()
            if (error) {
                var message = ""
                switch (error.response.status) {
                    case 400:
                        message = error.response.data
                        this.setState({
                            control:true,
                            controlErr: error.response.data
                        })
                        break
                    case 422:
                        message = "Ocorreram erros, verifiqueo formulário e tente novamente"
                        break
                    default:
                        message = "Ocorreram erros, verifique sua conexão com a internet"
                        break
                }
                showNotification(
                    "top",
                    "center",
                    message,
                    error.response.status === 400 ? "warning" : "danger"
                )
                if (error.response.status === 422) {
                    const reader = new FileReader()
                    reader.addEventListener("loadend", e => {
                        const text = e.srcElement.result
                        let json = JSON.parse(text)
                        this.setState({
                            errors: mapErrors({ response: { data: json } })
                        })
                    })
                    reader.readAsText(error.response.data)
                }
            } else {
                showNotification(
                    "top",
                    "center",
                    "Arquivo XML importado com sucesso!",
                    "success"
                )
                // var url = URL.createObjectURL(data)
                // let tempLink = document.createElement("a")
                // tempLink.setAttribute("target", "_blank")
                // tempLink.setAttribute("href", `${url}`)
                // tempLink.setAttribute("style", "display:none;")
                // document.body.appendChild(tempLink)
                // tempLink.click()
                // document.body.removeChild(tempLink)

            }
        })
    }
    render() {
        return (
            <Modal
                margin={this.props.margin}
                plain={"-outline"}
                color={"danger"}
                md={this.props.md || false}
                title="Importar XML"
                triggerLabel={"Importar XML"}
                triggerTitle="Importar XML"
                centralized
                id={"recibo" + this.props.movimentoId}
                triggerIcon={this.props.triggerIcon || "ni ni-paper-diploma"}
            >
                <form onSubmit={this.onSubmit}>
                    <Input
                        id={"xml"}
                        name="xml"
                        type="file"
                        placeholder="Importar arquivo XML"
                        onChange={this.handleChange}
                        disabled={this.state.isLoading}
                        error={this.state.control ? this.state.controlErr : this.state.errors.xml}

                    />
                    {this.state.isLoading && (
                        <div>
                            <div className="row">
                                <div className="col-lg-12 text-center">
                                    <div className="lds-ring">
                                        <div />
                                        <div />
                                        <div />
                                        <div />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 text-center">
                                    <h3 className="text-center">
                                        Aguarde por favor, essa tarefa pode levar alguns segundos!
                                    </h3>
                                </div>
                            </div>
                        </div>
                    )}
                    <button
                        disabled={this.state.isLoading}
                        className="btn btn-default"
                        type="submit"
                    >
                        Salvar XML
                    </button>
                </form>
            </Modal>
        )
    }
}
