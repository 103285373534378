export const API = "https://api.fin.drsoftware.gerentemunicipal.com.br/api/"
// export const API = "http://api.fin.moreia/api/"
export const CLIENT_ID = "2"
export const CLIENT_SECRET = "UFKSqIAbTe68A8F7bys1cdqTXKnp0ADaLu8jBLw7"
export function defaultHeaders() {
  var token = localStorage.getItem("token")
  return {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
    Accept: "application/json"
  }
}

export function mapErrors(error) {
  var errorData = error.response.data.errors
  return Object.keys(errorData).reduce((previous, key) => {
    previous[key] = errorData[key][0]
    return previous
  }, {})
}
function getCorrectColor(color) {
  switch (color) {
    case "success":
      return "#25a26a"
    case "danger":
      return "#ed213a"
    default:
      break
  }
}
export function showNotification(from, align, message, color) {
  const receivedColor = getCorrectColor(color)
  window.$.notify(
    {
      icon:
        color === "success"
          ? "fas fa-clipboard-check"
          : "fas fa-exclamation-circle",
      message: message,
      title: color === "success" ? " Sucesso - " : " Erro - "
    },
    {
      allow_dismiss: true,
      delay: 1000,
      type: color,
      timer: 1,
      placement: {
        from: from,
        align: align
      },
      animate: {
        enter: "animated bounceInDown",
        exit: "animated bounceOutUp"
      },
      template:
        `<div style="width:30%;background-color:${receivedColor}" class="text-white card shadow alert alert-{0}" role="alert">` +
        "<button type=\"button\" aria-hidden=\"true\" class=\"close\" data-notify=\"dismiss\">×</button>" +
        "<span style=\"margin-right: 10px;font-size: 20px;\" data-notify=\"icon\" class=\"ftext-white alert-inner--icon\"></span>" +
        "<span  class=\"alert-inner--text\"><strong data-notify=\"title\">{1}</strong></span><span class=\"font-weight-bold \" data-notify=\"message\">{2}</span>" +
        "</div>"
    }
  )
}
