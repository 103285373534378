import React, { Component } from "react"
import { withRouter } from "react-router-dom"

class Dashboard extends Component {
  render() {
    return (
      <div className="card shadow">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h1>Em desenvolvimento, volte em breve para novidades!</h1>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Dashboard)
