import React, { Component } from "react"
import { withRouter, Link } from "react-router-dom"
import { get, destroy, search } from "../helpers/origensApi"
import moment from "moment"
import ReactPaginate from "react-paginate"
import { showNotification } from "../helpers/Constants"
import { confirmAlert } from "react-confirm-alert"
import ReactCSSTransitionGroup from "react-addons-css-transition-group"
import { Search } from "../components"
import housecall from "housecall"
class Origens extends Component {
  constructor() {
    super()
    this.getData = this.getData.bind(this)
    this.handlePageClick = this.handlePageClick.bind(this)
    this.toggleLoading = this.toggleLoading.bind(this)
    this.togglePaginateLoading = this.togglePaginateLoading.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.filter = this.filter.bind(this)
    this.queue = housecall({ concurrency: 1, cooldown: 0 })
    this.state = {
      origens: [],
      filtered: [],
      filterText: "",
      filterFields: [],
      pageCount: 0,
      searchActive: false,
      isLoading: true
    }
  }
  componentDidMount() {
    this.queue.on("idle", this.toggleLoading)
    window.$("[data-toggle=\"tooltip\"]").tooltip({
      trigger: "hover"
    })
  }
  componentWillUnmount() {
    window.$(".tooltip").remove()
  }

  componentDidUpdate() {
    window.$("[data-toggle=\"tooltip\"]").tooltip({
      trigger: "hover"
    })
  }
  toggleLoading() {
    this.setState({
      isLoading: !this.state.isLoading
    })
  }
  togglePaginateLoading() {
    this.setState({
      isPaginationLoading: !this.state.isPaginationLoading
    })
  }
  delete(id, index) {
    confirmAlert({
      title: "Confirme a remoção",
      message: "Tem certeza que deseja deletar?",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            destroy(id, error => {
              if (error) {
                let errorMessage = (
                    (error.response || { data: { message: undefined } }).data || {
                      message: undefined
                    }
                ).message
                showNotification(
                    "top",
                    "center",
                    errorMessage || "Erro desconhecido",
                    "danger"
                )
              } else {
                showNotification(
                    "top",
                    "center",
                    "Origem deletada com sucesso",
                    "success"
                )
                let currentOrigens = this.state.origens
                currentOrigens.splice(index, 1)
                this.setState({
                  origens: currentOrigens
                })
              }
            })
          }
        },
        {
          label: "Não"
        }
      ]
    })
  }
  componentWillMount() {
    this.getData()
  }
  handlePageClick(data) {
    this.getData(data.selected + 1)
  }
  getData(page) {
    this.togglePaginateLoading()
    get(page, (error, data) => {
      this.togglePaginateLoading()
      if (error) {
        showNotification(
            "top",
            "center",
            "Ocorreram erros, verifique sua conexão com a internet",
            "danger"
        )
      } else {
        if (!page) {
          this.toggleLoading()
        }
        this.setState({
          origens: data.data,
          pageCount: data.last_page
        })
      }
    })
  }
  filterRequest() {
    if (this.state.filterText !== "") {
      let filtersCheckbox = this.state.filterFields.join(",") || ["id", "nome"]
      this.setState(
          {
            searchActive: true
          },
          () => {
            this.queue.push(() =>
                search(
                    this.state.filterText,
                    filtersCheckbox,
                    () => {
                      if (!this.state.isLoading) this.toggleLoading()
                    },
                    (error, data) => {
                      if (error) {
                        showNotification(
                            "top",
                            "center",
                            "Ocorreram erros, verifique sua conexão com a internet",
                            "danger"
                        )
                      } else {
                        this.setState({
                          filtered: data
                        })
                      }
                    }
                )
            )
          }
      )
    } else {
      this.setState({
        searchActive: false
      })
    }
  }
  handleChange(e) {
    let name = e.target.name
    let checked = e.target.value
    if (checked) {
      if (name === "todos") {
        this.setState(
            {
              filterFields: []
            },
            () => {
              this.filterRequest()
            }
        )
      } else {
        this.setState(
            state => ({
              filterFields: [...state.filterFields, name]
            }),
            () => {
              this.filterRequest()
            }
        )
      }
    } else {
      this.setState(
          state => ({
            filterFields: state.filterFields.filter(item => {
              return item !== name
            })
          }),
          () => {
            this.filterRequest()
          }
      )
    }
  }
  filter(e) {
    let value = e.target.value.toLowerCase()
    this.setState(
        {
          filterText: value
        },
        () => {
          this.filterRequest()
        }
    )
  }
  render() {
    let searchColumns = [
      {
        value: "id",
        label: "Código",
        checked: this.state.filterFields.includes("id")
      },
      {
        value: "nome",
        label: "Nome",
        checked: this.state.filterFields.includes("nome")
      }
    ]
    return (
        <div className="card shadow">
          <div className="card-header border-0 bg-secondary">
            <div className="row text-center">
              <div className="col-lg-12">
                <h1>Origens</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 offset-lg-4 text-center">
                <Link to="/origens/create" className="btn btn-success btn-block">
                <span className="btn-inner--icon">
                  <i className="ni ni-fat-add" />
                </span>
                  <span className="btn-inner--text"> Adicionar</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="card-body">
            <Search
                searchColumns={searchColumns}
                filterTextChanged={this.filter}
                filterChanged={this.handleChange}
                noneSelected={this.state.filterFields.length === 0}
            />
            {this.state.isLoading ? (
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <div className="lds-ring">
                      <div />
                      <div />
                      <div />
                      <div />
                    </div>
                  </div>
                </div>
            ) : (
                <div className="table-responsive">
                  <table className="table align-items-center table-flush">
                    <thead className="thead-light">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Nome</th>
                      <th scope="col">Data de início</th>
                      <th scope="col">Data final</th>
                      <th style={{ width: "15%" }} scope="col">
                        Ações
                      </th>
                    </tr>
                    </thead>
                    <ReactCSSTransitionGroup
                        component="tbody"
                        transitionEnterTimeout={500}
                        transitionLeaveTimeout={500}
                        transitionName="example"
                    >
                      {(this.state.searchActive
                              ? this.state.filtered
                              : this.state.origens
                      ).map((item, key) => {
                        return (
                            <tr key={key}>
                              <td className="normal-wrap">{item.id}</td>
                              <td className="normal-wrap">{item.nome}</td>
                              <td className="normal-wrap">
                                {item.data_inicio !== null
                                    ? moment(item.data_inicio).format("DD/MM/YYYY")
                                    : "N/A"}
                              </td>
                              <td className="normal-wrap">
                                {item.data_final !== null
                                    ? moment(item.data_final).format("DD/MM/YYYY")
                                    : "N/A"}
                              </td>
                              <td className="normal-wrap">
                                <Link
                                    to={`origens/edit/${item.id}`}
                                    className="btn btn-outline-info"
                                    data-toggle="tooltip"
                                    title="Editar origem"
                                >
                            <span className="btn-inner--icon">
                              <i className="ni ni-ruler-pencil" />
                            </span>
                                </Link>
                                <button
                                    onClick={() => this.delete(item.id, key)}
                                    type="button"
                                    data-toggle="tooltip"
                                    title="Deletar origem"
                                    className="btn btn-outline-danger"
                                >
                            <span className="btn-inner--icon">
                              <i className="ni ni-fat-remove" />
                            </span>
                                </button>
                              </td>
                            </tr>
                        )
                      })}
                    </ReactCSSTransitionGroup>
                  </table>
                </div>
            )}
          </div>
          <div className="card-footer">
            <ReactPaginate
                previousLabel={<i className="fa fa-angle-left" />}
                nextLabel={<i className="fa fa-angle-right" />}
                breakLabel={<i className="fa fa-ellipsis-h" />}
                breakClassName="break-me"
                pageCount={this.state.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={15}
                pageClassName={`page-item ${
                    this.state.isPaginationLoading ? "disabled" : ""
                }`}
                onPageChange={this.handlePageClick}
                containerClassName="pagination justify-content-center"
                subContainerClassName={`page-item ${
                    this.state.isPaginationLoading || this.state.searchActive
                        ? "disabled"
                        : ""
                }`}
                pageLinkClassName="page-link"
                nextClassName={`page-item ${
                    this.state.isPaginationLoading || this.state.searchActive
                        ? "disabled"
                        : ""
                }`}
                nextLinkClassName="page-link"
                previousClassName={`page-item ${
                    this.state.isPaginationLoading || this.state.searchActive
                        ? "disabled"
                        : ""
                }`}
                previousLinkClassName="page-link"
                activeClassName="active"
            />
          </div>
        </div>
    )
  }
}
export default withRouter(Origens)
