import React, { Component } from "react"
import { Input, TextArea, InputCurrency, Select } from "../../components"
import { withRouter } from "react-router-dom"
import { post, edit, put, create } from "../../helpers/origensApi"
import { mapErrors, showNotification } from "../../helpers/Constants"

class OrigemForm extends Component {
  constructor() {
    super()
    this.handleChange = this.handleChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.toggleLoading = this.toggleLoading.bind(this)
    this.state = {
      errors: {},
      isLoading: true,
      especies: [],
      tiposDeMovimento: [],
      tiposDeDocumento: []
    }
  }

  componentWillMount() {
    this.getInitialData()
  }
  getInitialData() {
    let response = (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
      } else {
        let especies = data.especies.map(especie => {
          return { value: especie.id, label: especie.nome }
        })
        let tiposDeDocumento = data.tipos_de_documento.map(tipo => {
          return { value: tipo.id, label: tipo.nome }
        })
        let tiposDeMovimento = data.tipos_de_movimento.map(tipo => {
          return { value: tipo.id, label: tipo.nome }
        })
        let newState = {
          tiposDeMovimento: tiposDeMovimento,
          tiposDeDocumento: tiposDeDocumento,
          especies: especies
        }
        if (this.props.match.params.id) {
          data.valor_mensal = parseFloat(data.valor_mensal)
          newState = { ...newState, ...data.origem }
        }
        this.setState(newState, () => {
          this.toggleLoading()
        })
      }
    }
    if (this.props.match.params.id) {
      edit(this.props.match.params.id, response)
    } else {
      create(response)
    }
  }
  toggleLoading() {
    this.setState({
      isLoading: !this.state.isLoading
    })
  }
  handleChange(e) {
    let name = e.target.name
    let value = e.target.value
    let errors = this.state.errors
    errors[name] = undefined
    this.setState({
      [name]: value,
      errors: errors
    })
  }
  onSubmit(e) {
    e.preventDefault()
    let params = {
      data_inicio: this.state.data_inicio,
      data_final: this.state.data_final,
      historico: this.state.historico,
      nome: this.state.nome,
      gerar_movimentos: this.state.gerar_movimentos || false,
      tipo_id: this.state.tipo_id ? this.state.tipo_id.value : undefined,
      tipo_de_documento_id: this.state.tipo_documento_id
        ? this.state.tipo_documento_id.value
        : undefined,
      gerado: this.state.gerado || this.state.gerar_movimentos,
      especie_id: this.state.especie_id
        ? this.state.especie_id.value
        : undefined,
      valor_mensal: this.state.valor_mensal
    }
    let response = error => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
        this.setState({
          errors: mapErrors(error)
        })
      } else {
        showNotification(
          "top",
          "center",
          `Origem ${
            this.props.match.params.id ? "editada" : "incluída"
          } com sucesso`,
          "success"
        )
        this.props.history.push("/origens")
      }
    }
    this.props.match.params.id
      ? put(this.props.match.params.id, params, response)
      : post(params, response)
  }
  render() {
    return (
      <div className="card shadow">
        <div className="card-header border-0 bg-secondary">
          <div className="row text-center">
            <div className="col-lg-12">
              <h1>
                {this.props.match.params.id
                  ? "Editar origem"
                  : "Adicionar nova origem"}
              </h1>
            </div>
          </div>
        </div>
        <div className="card-body">
          {this.state.isLoading ? (
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="lds-ring">
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              </div>
            </div>
          ) : (
            <form onSubmit={this.onSubmit}>
              <div className="row">
                <div className="col-lg-4">
                  <Input
                    label="Nome"
                    name="nome"
                    placeholder="Nome"
                    defaultValue={this.state.nome}
                    onChange={this.handleChange}
                    error={this.state.errors.nome}
                  />
                </div>
                <div className="col-lg-4">
                  <Input
                    label="Data inicial"
                    name="data_inicio"
                    placeholder="Data inicial"
                    type="date"
                    defaultValue={this.state.data_inicio}
                    onChange={this.handleChange}
                    error={this.state.errors.data_inicio}
                  />
                </div>
                <div className="col-lg-4">
                  <Input
                    label="Data final"
                    name="data_final"
                    type="date"
                    placeholder="Data final"
                    onChange={this.handleChange}
                    defaultValue={this.state.data_final}
                    error={this.state.errors.data_final}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <InputCurrency
                    label="Valor mensal"
                    defaultValue={this.state.valor_mensal || ""}
                    name="valor_mensal"
                    error={this.state.errors.valor_mensal}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <TextArea
                    label="Histórico"
                    name="historico"
                    defaultValue={this.state.historico || ""}
                    placeholder="Histórico"
                    rows="4"
                    onChange={this.handleChange}
                    error={this.state.errors.historico}
                  />
                </div>
              </div>
              {this.state.gerado && (
                <div className="row py-3 bg-secondary">
                  <div className="offset-lg-3 col-lg-6">
                    <h4 className="text-warning text-center">
                      Atenção: Essa origem já possui movimentos gerados
                    </h4>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="offset-lg-4 col-lg-4">
                  <Input
                    label="Gerar movimentos para esta origem?"
                    name="gerar_movimentos"
                    type="checkbox"
                    placeholder="Gerar movimentos para esta origem?"
                    onChange={this.handleChange}
                    value={this.state.gerar_movimentos}
                    error={this.state.errors.gerar_movimentos}
                  />
                </div>
              </div>
              {this.state.gerar_movimentos && (
                <div className="card shadow">
                  <div className="card-header border-0 bg-secondary text-center">
                    <h3>
                      Vencimento:
                      {(
                        (this.state.data_inicio
                          ? new Date(this.state.data_inicio)
                          : new Date()
                        ).getDay() + ""
                      ).padStart(2, "0")}
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-4">
                        <Select
                          label="Tipo de movimento"
                          name="tipo_id"
                          placeholder="Tipo de movimento"
                          options={this.state.tiposDeMovimento}
                          value={this.state.tipo_id}
                          onChange={this.handleChange}
                          error={this.state.errors.tipo_id}
                        />
                      </div>
                      <div className="col-lg-4">
                        <Select
                          label="Tipo de documento"
                          name="tipo_documento_id"
                          placeholder="Tipo de documento"
                          options={this.state.tiposDeDocumento}
                          value={this.state.tipo_documento_id}
                          onChange={this.handleChange}
                          error={this.state.errors.tipo_documento_id}
                        />
                      </div>
                      <div className="col-lg-4">
                        <Select
                          label="Espécie"
                          name="especie_id"
                          placeholder="Espécie"
                          options={this.state.especies}
                          value={this.state.especie_id}
                          onChange={this.handleChange}
                          error={this.state.errors.especie_id}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="row mt-3">
                <div className="col-lg-2 offset-lg-5">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block btn-lg"
                  >
                    <span className="btn--inner-icon">
                      <i className="ni ni-send" />
                    </span>
                    <span className="btn--inner-text"> Enviar</span>
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    )
  }
}

export default withRouter(OrigemForm)
