import axios from "axios"
import {API, defaultHeaders} from "./Constants"

export function resumoGeral(params, callBack) {
    let headers = defaultHeaders()
    let config = {
        headers: headers,
        responseType: "blob"
    }
    let url = API + "resumo"
    axios
        .post(url, params, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}

export function transferencias(params, callBack) {
    let headers = defaultHeaders()
    let config = {
        headers: headers,
        responseType: "blob"
    }
    let url = API + "transferenciasReport"
    axios
        .post(url, params, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}

export function movimentoGeral(params, callBack) {
    let headers = defaultHeaders()
    let config = {
        headers: headers,
        responseType: "blob"
    }
    let url = API + "movimentoGeral"
    axios
        .post(url, params, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}

export function movimentoAPagar(params, callBack) {
    let headers = defaultHeaders()
    let config = {
        headers: headers,
        responseType: "blob"
    }
    let url = API + "movimentoAPagar"
    axios
        .post(url, params, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}

export function movimentoDeCaixaGeral(params, callBack) {
    let headers = defaultHeaders()
    let config = {
        headers: headers,
        responseType: "blob"
    }
    let url = API + "movimentoGeralDeCaixa"
    axios
        .post(url, params, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}

export function pagamentosPorPeriodo(params, callBack) {
    let headers = defaultHeaders()
    let config = {
        headers: headers,
        responseType: "blob"
    }
    let url = API + "pagamentosPorPeriodo"
    axios
        .post(url, params, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}

export function recibo(movimentoId, params, callBack) {
    let headers = defaultHeaders()
    let config = {
        headers: headers,
        responseType: "blob"
    }
    let url = API + `recibo/${movimentoId}`
    axios
        .post(url, params, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}

export function importaXml(params, callBack) {
    let headers = defaultHeaders()
    headers["Content-Type"] = "multipart/form-data"
    let config = {
        headers: headers,
        responseType: "json"
    }
    let url = API + `movimentos/importaXml`
    axios
        .post(url, params, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}
