import axios from "axios"
import { API, CLIENT_ID, CLIENT_SECRET } from "./Constants"

export function login(params, callback) {
  let address = API.split("api/").join("oauth/token")
  params.grant_type = "password"
  params.client_id = CLIENT_ID
  params.client_secret = CLIENT_SECRET
  axios
    .post(address, params)
    .then(response => {
      localStorage.token = response.data.access_token
      callback(undefined, response.data)
    })
    .catch(error => {
      callback(error)
    })
}
